<template>
  <div>

    <div>
      <b-modal
        id="addVideoDialog"
        ref="addVideoDialog"
        hide-footer
        centered
        title="Add Video"
      >
        <validation-observer
          ref="info"
          tag="form"
        ><b-row>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="Video"
              rules="required"
            >
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label>Video</label>
                </div>
                <b-form-input
                  id="url"
                  v-model="addVideos.video_url"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Url"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>

        </validation-observer>

        <b-col
          cols="12"
          md="12"
        >
          <b-button
            v-if="!loaderVideo"
            variant="primary"
            class="mr-1"
            @click.prevent="addVideo"
          >
            Save
          </b-button>
          <b-button
            variant="outline-danger"
            class="closeModal"
            @click="closeAddVideoDialog()"
          >
            Cancel
          </b-button>
          <b-button
            v-if="loaderVideo"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-modal>
    </div>
    <b-card>
      <validation-observer
        ref="infoRules"
        tag="form"
      >
        <div
          class="d-flex justify-content-end "
        >
          <b-button
            variant="primary"
            class="mb-1"
            @click="openAddVideoDialog()"
          >
            add Video
          </b-button>
        </div>
        <b-row>
          <b-col
            v-for="index in videosCount"
            :key="index"
            md="6"
          >
            <!-- Name -->

            <div class="d-flex justify-content-between">
              <label>Video {{ index }}</label> <feather-icon
                v-b-tooltip.hover
                class="pointer mx-1"
                title="delete"
                icon="Trash2Icon"
                color="red"
                size="20"
                @click="deleteVideo(videos[index - 1].id,index)"
              />
            </div>
            <youtube
              ref="youtube"
              :video-id="getId(videos[index - 1])"
            />

          </b-col>
        </b-row>
      </validation-observer>
      <div
        v-if="videosCount==0"
        class="text-center mb-5"
      >
        No Videos Found
      </div>
    </b-card>
  </div>

</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {

  data() {
    return {
      videosCount: 1,
      required,
      loader: false,

      loaderVideo: false,

      validationErrors: {},
      errorMsg: '',
      errors: {},

      image: null,
      addVideos: {
        video_url: '',

      },
      videos: [
        {
          video_url: '',

        },
      ],
    }
  },
  setup() {
    const { getValidationState } = formValidation()

    return {
      getValidationState,

    }
  },

  mounted() {
    this.showVideos()
  },
  methods: {

    getId(video) {
      return this.$youtube.getIdFromUrl(video.video_url)
    },

    closeAddVideoDialog() {
      this.$refs.addVideoDialog.hide()
    },

    openAddVideoDialog() {
      this.addVideos = {
        video_url: '',

      }
      this.$refs.addVideoDialog.show()
    },

    deleteVideo(id, index) {
      if (id) {
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        }).then(result => {
          if (result.isConfirmed) {
            axios.get(`admin/delete_video/${id}`).then(() => {
              this.$swal(
                'Deleted!',
                'Deleted Successfully.',
                'success',
              )

              this.showVideos()
            }).finally(() => {
              this.loading = false
            })
          }
        })
      } else {
        this.videos.splice(index, 1)
        this.videosCount -= 1
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Succesfully',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      }
    },
    selectFile() {
      this.$refs.refInputEl.$el.click()
    },
    changeProfile(e) {
      // eslint-disable-next-line prefer-destructuring
      this.image = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.stepEdit.image = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    showVideos() {
      axios.get('admin/guide_videos').then(res => {
        this.videos = res.data.data.videos
        this.videosCount = this.videos.length
      })
    },
    addVideo() {
      this.loaderVideo = true
      axios.post('admin/add_video', this.addVideos).then(res => {
        if (res.status === 200) {
          this.$refs.addVideoDialog.hide()
          this.showVideos()
          this.loaderVideo = false

          this.$toasted.show('Added Successfully', {
            position: 'top-center',
            duration: 3000,
          })
        }
      }).catch(error => {
        if (error.response.status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ooops!!! An Unexpected Internal server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } else if (error.response.status === 422) {
          if (error.response.data.errors !== undefined) {
            this.errorMsg = error.response.data.errors
          } else {
            this.errorMsg = error.response.data.message
          }
        }
      }).finally(() => {
        this.loaderVideo = false
      })
    },
  },
}
</script>

  <style lang="scss" scoped>
  label{
    font-size: 1rem;
  }
  </style>
