<template>

  <div>

    <b-card>

      <div
        class="d-flex justify-content-end "
      >
        <b-button
          variant="primary"
          class="mb-1"
          @click="openAddStepDialog()"
        >
          add Step
        </b-button>
      </div>
      <div v-if="stepsCount!==0">

        <b-row
          v-for="index in stepsCount"
          :key="index"
        >
          <b-col cols="12">
            <div class="d-flex justify-content-between">
              <p class="primary--text">
                Step {{ index }}
                <feather-icon
                  v-b-tooltip.hover
                  class="pointer"
                  title="Edit"
                  icon="EditIcon"
                  color="#f48a36"
                  size="20"
                  @click="openStepDialog(steps[index - 1])"
                />
              </p>
              <feather-icon
                v-b-tooltip.hover
                size="20"
                class="pointer"
                title="delete"
                icon="Trash2Icon"
                color="red"
                @click="deleteItem(steps[index - 1].id,index)"
              />
            </div>

          </b-col>
          <b-col md="6">
            <!-- Name -->

            <b-form-group
              label="Title (En)"
              label-for="name"
            >
              <b-form-input
                :id="`Title (En) ${index}`"
                v-model="steps[index - 1].title_en"

                trim
                readonly
                placeholder="Title"
              />

            </b-form-group>
          </b-col>
          <b-col md="6">
            <!-- Name -->

            <b-form-group
              label="Title (Ar)"
              label-for="name"
            >
              <b-form-input
                :id="`Title (Ar) ${index}`"
                v-model="steps[index - 1].title_ar"

                trim
                readonly
                placeholder="Title"
              />

            </b-form-group>
          </b-col>
          <b-col md="6">

            <b-form-group
              label="Description (En)"
              label-for="description"
            >
              <b-form-textarea
                :id="`Description (En) ${index}`"
                v-model="steps[index - 1].description_en"
                trim
                type="text"
                readonly
                placeholder="Description"
              />

            </b-form-group>
          </b-col>
          <b-col md="6">

            <b-form-group
              label="Description (Ar)"
              label-for="description"
            >
              <b-form-textarea
                :id="`Description (Ar) ${index}`"
                v-model="steps[index - 1].description_ar"
                trim
                type="text"
                readonly
                placeholder="Description"
              />

            </b-form-group>
          </b-col>
          <b-col
            v-if="steps[index - 1].image"
            md="2"
          >
            <img
              v-img
              :src="steps[index - 1].image"
              class="w-100"
            >
          </b-col>
        </b-row>
      </div>
      <div
        v-else
        class="text-center mb-5"
      >
        No Steps Found
      </div>
    </b-card>
    <div>
      <b-modal
        id="editStep"
        ref="editStep"
        hide-footer
        centered
        title="Edit Step"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>

            <b-col md="6">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                name="Title"
                rules="required"
              >
                <b-form-group
                  label="Title (En)"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="stepEdit.title_en"

                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Title"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                name="Title"
                rules="required"
              >
                <b-form-group
                  label="Title (Ar)"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="stepEdit.title_ar"

                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Title"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                rules="required"
                name="Description"
              >
                <b-form-group
                  label="Description (En)"
                  label-for="description"
                >
                  <b-form-textarea
                    id="description"
                    v-model="stepEdit.description_en"
                    trim
                    type="text"
                    placeholder="Description"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                rules="required"
                name="Description"
              >
                <b-form-group
                  label="Description (Ar)"
                  label-for="description"
                >
                  <b-form-textarea
                    id="description"
                    v-model="stepEdit.description_ar"
                    trim
                    type="text"
                    placeholder="Description"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <b-media>

                <b-media-aside>

                  <div class="d-flex">

                    <b-img
                      ref="previewEl"
                      v-img
                      :src="stepEdit.image"
                      class="ml-1 typeImg"
                      height="100"
                      width="100"
                    />

                  </div>

                  <!--/ image -->
                </b-media-aside>

                <b-media-body class="mt-75 ml-75">
                  <!-- upload button -->
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mb-75 mr-75"
                    @click="selectFile()"
                  >
                    Upload Image
                  </b-button>
                  <validation-provider
                    #default="validationContext"
                    name="type image"
                  >
                    <b-form-group>
                      <b-form-file
                        ref="refInputEl"
                        accept=".jpg, .png"
                        :hidden="true"
                        plain
                        multiple
                        :state="getValidationState(validationContext)"
                        @change="changeProfile($event) "
                      />
                    </b-form-group>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                  <!--/ upload button -->
                </b-media-body>
              </b-media>
            </b-col>
          </b-row></validation-observer>

        <b-col
          cols="12"
          md="12"
        >
          <b-button
            v-if="!loaderEditStep"
            variant="primary"
            class="mr-1"
            @click.prevent="editStep"
          >
            Save
          </b-button>
          <b-button
            variant="outline-danger"
            class="closeModal"
            @click="closeEditStepModal()"
          >
            Cancel
          </b-button>
          <b-button
            v-if="loaderEditStep"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-modal>
    </div>
    <div>
      <b-modal
        id="addStep"
        ref="addStep"
        hide-footer
        centered
        title="Add Step"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>

            <b-col md="6">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                name="Title"
                rules="required"
              >
                <b-form-group
                  label="Title (En)"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="stepEdit.title_en"

                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Title"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                name="Title"
                rules="required"
              >
                <b-form-group
                  label="Title (Ar)"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="stepEdit.title_ar"

                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Title"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                rules="required"
                name="Description"
              >
                <b-form-group
                  label="Description (En)"
                  label-for="description"
                >
                  <b-form-textarea
                    id="description"
                    v-model="stepEdit.description_en"
                    trim
                    type="text"
                    placeholder="Description"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                rules="required"
                name="Description"
              >
                <b-form-group
                  label="Description (Ar)"
                  label-for="description"
                >
                  <b-form-textarea
                    id="description"
                    v-model="stepEdit.description_ar"
                    trim
                    type="text"
                    placeholder="Description"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <b-media>

                <b-media-aside>

                  <div class="d-flex">

                    <b-img
                      ref="previewEl"
                      v-img
                      :src="stepEdit.image"
                      class="ml-1 typeImg"
                      height="100"
                      width="100"
                    />

                  </div>

                  <!--/ image -->
                </b-media-aside>

                <b-media-body class="mt-75 ml-75">
                  <!-- upload button -->
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mb-75 mr-75"
                    @click="selectFile()"
                  >
                    Upload Image
                  </b-button>
                  <validation-provider
                    #default="validationContext"
                    name="type image"
                  >
                    <b-form-group>
                      <b-form-file
                        ref="refInputEl"
                        accept=".jpg, .png"
                        :hidden="true"
                        plain
                        multiple
                        :state="getValidationState(validationContext)"
                        @change="changeProfile($event) "
                      />
                    </b-form-group>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                  <!--/ upload button -->
                </b-media-body>
              </b-media>
            </b-col>
          </b-row></validation-observer>

        <b-col
          cols="12"
          md="12"
        >
          <b-button
            v-if="!loaderStep"
            variant="primary"
            class="mr-1"
            @click.prevent="addStep"
          >
            Save
          </b-button>
          <b-button
            variant="outline-danger"
            class="closeModal"
            @click="closeAddStepModal()"
          >
            Cancel
          </b-button>
          <b-button
            v-if="loaderStep"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-modal>
    </div>

  </div>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
  },
  data() {
    return {
      stepsCount: 1,
      required,
      loader: false,
      loaderStep: false,
      loaderEditStep: false,
      stepEdit:
          {
            title_en: '',
            title_ar: '',
            description_ar: '',
            description_en: '',
            image: null,

          },
      validationErrors: {},
      errorMsg: '',
      errors: {},

      steps: [
        {
          title_en: '',
          title_ar: '',
          description_ar: '',
          description_en: '',
          image: null,

        }],
      image: null,

    }
  },
  setup() {
    const { getValidationState } = formValidation()

    return {
      getValidationState,

    }
  },

  mounted() {
    this.showSteps()
  },
  methods: {
    deleteItem(id, index) {
      if (id) {
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        }).then(result => {
          if (result.isConfirmed) {
            axios.get(`admin/delete_guide_step/${id}`).then(() => {
              this.$swal(
                'Deleted!',
                'Deleted Successfully.',
                'success',
              )

              this.showSteps()
            }).finally(() => {
              this.loading = false
            })
          }
        })
      } else {
        this.steps.splice(index, 1)
        this.stepsCount -= 1
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Succesfully',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      }
    },

    closeAddStepModal() {
      this.$refs.addStep.hide()
    },
    closeEditStepModal() {
      this.$refs.editStep.hide()
    },

    openStepDialog(step) {
      this.stepEdit = step
      this.$refs.editStep.show()
    },
    openAddStepDialog() {
      this.stepEdit = {
        title_en: '',
        title_ar: '',
        description_ar: '',
        description_en: '',
        image: null,

      }

      this.$refs.addStep.show()
    },

    editStep() {
      const formData = new FormData()
      this.loaderEditStep = true
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const key in this.stepEdit) {
        if (key !== 'image') {
          formData.append(key, this.stepEdit[key])
        }
      }
      if (this.image) {
        formData.append('image', this.image)
      }
      axios.post(`admin/update_guide_step/${this.stepEdit.id}`, formData).then(res => {
        if (res.status === 200) {
          this.$refs.editStep.hide()
          this.loaderEditStep = false

          this.$toasted.show('Updated Successfully', {
            position: 'top-center',
            duration: 3000,
          })
        }
      }).catch(error => {
        if (error.response.status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ooops!!! An Unexpected Internal server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } else if (error.response.status === 422) {
          if (error.response.data.errors !== undefined) {
            this.errorMsg = error.response.data.errors
          } else {
            this.errorMsg = error.response.data.message
          }
        }
      }).finally(() => {
        this.loaderEditStep = false
      })
    },

    showSteps() {
      axios.get('admin/guide_steps').then(res => {
        this.steps = res.data.data.steps

        this.stepsCount = this.steps.length
      })
    },

    addStep() {
      const formData = new FormData()
      this.loaderStep = true
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const key in this.stepEdit) {
        if (key !== 'image') {
          formData.append(key, this.stepEdit[key])
        }
      }
      if (this.image) {
        formData.append('image', this.image)
      }
      axios.post('admin/add_guide_step', formData).then(res => {
        if (res.status === 200) {
          this.$refs.addStep.hide()
          this.loaderStep = false
          this.stepEdit = {}
          this.showSteps()
          this.$toasted.show('Added Successfully', {
            position: 'top-center',
            duration: 3000,
          })
        }
      }).catch(error => {
        if (error.response.status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ooops!!! An Unexpected Internal server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } else if (error.response.status === 422) {
          if (error.response.data.errors !== undefined) {
            this.errorMsg = error.response.data.errors
          } else {
            this.errorMsg = error.response.data.message
          }
        }
      }).finally(() => {
        this.loaderStep = false
      })
    },

    selectFile() {
      this.$refs.refInputEl.$el.click()
    },
    changeProfile(e) {
      // eslint-disable-next-line prefer-destructuring
      this.image = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.stepEdit.image = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },

  },
}
</script>

  <style lang="scss" scoped>
  label{
    font-size: 1rem;
  }
  </style>
