<template>
  <b-tabs
    pills

    vertical
  >
    <b-tab
      title="Title"
      active
    >
      <Title />
    </b-tab>
    <b-tab title="Steps">

      <Steps />

    </b-tab>
    <b-tab title="Videos">
      <Videos />

    </b-tab>
  </b-tabs>
</template>

<script>

import Title from './components/title.vue'
import Steps from './components/steps.vue'
import Videos from './components/videos.vue'

export default {
  components: {
    Title,
    Steps,
    Videos,
  },
  data() {
    return {

    }
  },

}
</script>

<style lang="scss" scoped>
label{
  font-size: 1rem;
}
</style>
