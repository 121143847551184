<template>

  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Title -->
          <validation-provider
            #default="validationContext"
            name="Title (En)"
            rules="required"
          >
            <b-form-group
              label="Title (En)"
              label-for="name"
            >
              <b-form-input
                id="title (En)"
                v-model="title.title_en"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Title"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Title -->
          <validation-provider
            #default="validationContext"
            name="Title (Ar)"
            rules="required"
          >
            <b-form-group
              label="Title (Ar)"
              label-for="title"
            >
              <b-form-input
                id="title (Ar)"
                v-model="title.title_ar"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Title"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Description (En)"
          >
            <b-form-group
              label="Description (En)"
              label-for="description"
            >
              <b-form-textarea
                id="description (En)"
                v-model="title.description_en"
                trim
                type="text"
                placeholder="Description"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Description (Ar)"
          >
            <b-form-group
              label="Description (Ar)"
              label-for="description"
            >
              <b-form-textarea
                id="description (Ar)"
                v-model="title.description_ar"
                trim
                type="text"
                placeholder="Description"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="errorMsg"
          cols="12"
        >
          <p class="text-danger">
            {{ errorMsg }}
          </p>
        </b-col>
        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >tt{{ Object.values(errors) }}
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loaderTitle"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="updateTitle()"
          >
            Save
          </b-button>
          <b-button
            v-if="loaderTitle"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      required,
      loader: false,
      loaderTitle: false,

      validationErrors: {},
      errorMsg: '',
      errors: {},

      title:
        {
          title_en: '',
          title_ar: '',
          description_ar: '',
          description_en: '',

        },
    }
  },
  setup() {
    const { getValidationState } = formValidation()

    return {
      getValidationState,

    }
  },

  mounted() {
    this.showTitle()
  },
  methods: {

    updateTitle() {
      this.loaderTitle = true
      axios.post('admin/update_how_to_work', this.title).then(res => {
        if (res.status === 200) {
          this.showTitle()
          this.loaderTitle = false
          this.$toasted.show('Updated Successfully', {
            position: 'top-center',
            duration: 3000,
          })
        }
      }).catch(error => {
        if (error.response.status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ooops!!! An Unexpected Internal server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } else if (error.response.status === 422) {
          if (error.response.data.errors !== undefined) {
            this.errorMsg = error.response.data.errors
          } else {
            this.errorMsg = error.response.data.message
          }
        }
      }).finally(() => {
        this.loaderTitle = false
      })
    },

    showTitle() {
      axios.get('admin/how_to_work').then(res => {
        this.title = res.data.data.how_to_work
      })
    },

  },
}
</script>

<style lang="scss" scoped>
label{
  font-size: 1rem;
}
</style>
